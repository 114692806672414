import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.share.drive');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { FlatTreeControl } from '@angular/cdk/tree';
import { FileNode, FileDataSource } from "src/libs/drive/file";



@Component({
    selector: 'wiz-portal-share-drive',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.share.drive/view.scss */
.offcanvas {
  width: 100%;
  max-width: 720px;
}
.offcanvas textarea.form-control {
  height: auto;
}
.offcanvas .offcanvas-body .btn {
  padding: 8px 16px;
}

.container-full {
  max-width: 1780px;
  height: 100%;
  margin: auto;
}
.container-full .container-title {
  margin-top: 30px;
}
.container-full .container-title .warn {
  margin: 10px 10px;
  color: red;
}
.container-full .container-title label {
  font-weight: bold;
}
.container-full .container-drive {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.container-full .container-drive .folder-list {
  width: 300px;
  height: 100%;
  padding: 10px 0px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: white;
}
.container-full .container-drive .folder-list .list-header {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}
.container-full .container-drive .folder-list .list-header h4 {
  margin: 0px;
}
.container-full .container-drive .folder-list .list-header:hover {
  background-color: #E9E9E9;
}
.container-full .container-drive .folder-list .list-body {
  margin: 0px 10px;
  padding: 8px 16px;
}
.container-full .container-drive .folder-list .tree-node {
  width: 100%;
  cursor: pointer;
}
.container-full .container-drive .folder-list .tree-node:hover {
  background-color: #E9E9E9;
}
.container-full .container-drive .navigation {
  padding: 8px 16px;
  background: #B0B0E8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.container-full .container-drive table {
  margin-bottom: 0px;
}
.container-full .container-drive table tr {
  background: white;
  border-style: solid;
}
.container-full .container-drive table tr td {
  padding: 10px 16px;
}
.container-full .container-drive table tr td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
}
.container-full .container-drive table tr td .avatar-container.folder {
  background-color: #B0B0E8;
  color: #EDEEF8;
}
.container-full .container-drive table tr td .avatar-container.file {
  background-color: #EDEEF8;
  color: #B0B0E8;
}
.container-full .container-drive table tr td .avatar-container i {
  width: 32px;
  align-items: center;
}
.container-full .button_control {
  position: relative;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.container-full .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.container-full .button_control:hover .tooltip {
  opacity: 1;
}
.container-full .button_control i {
  display: inline-block;
  vertical-align: top;
}
.container-full .button_control .tooltip {
  position: absolute;
  top: -30px;
  left: 240px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.w-1 {
  width: 8px;
  white-space: nowrap;
  text-align: center;
}

.btn-a {
  background: #EDEEF8;
  border-radius: 999px;
}

.btn-b {
  border: 1px solid #B0B0E8;
  background: white;
  border-radius: 999px;
}

.btn-b:hover {
  background-color: #B0B0E8;
  color: white;
}

.blank {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: #5040F1;
}`],
})
export class PortalShareDriveComponent implements OnInit {
    private storage = null;
    private root = null;
    private base = null;
    private path = null;
    private size = null;

    public rootNode: FileNode;
    private treeControl: FlatTreeControl<FileNode>;
    private dataSource: FileDataSource;
    private getLevel = (node: FileNode) => node.level;
    private isExpandable = (node: FileNode) => node.extended;

    private mkdir = false;
    private dirName = "";

    private selectAll = false;
    private isRenaming = {};


    public macroTF = false;
    public fileSelect = [];
    public selected = "none";
    public macroFilepath: any;
    public show = false;

    public oneMonthAfter: Date;
    public nowDate: Date;


    constructor(@Inject( Service    )         public service: Service    ) {
        this.rootNode = new FileNode('root', this.root, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);
    }

    public async ngOnInit() {
        await this.service.init();

        this.rootNode = new FileNode('root', this.root, 'folder');
        this.treeControl = new FlatTreeControl<FileNode>(this.getLevel, this.isExpandable);
        this.dataSource = new FileDataSource(this);

        await this.init();
    }

    private async init() {
        let { code, data } = await wiz.call("init");
        if (code !== 200) {
            alert("오류가 발생했습니다. 다시 시도해주세요.");
            location.reload();
        }
        this.root = data.path;
        this.base = data.path;
        this.size = data.total_size;

        let res = await this.list(this.rootNode);
        this.dataSource.data = res;

        await this.load();
    }

    public drive_period = '';
    public async load() {
        let { code, data } = await wiz.call("load", { base: this.base });

        this.user = data.user;
        this.files = data.files.filter(file => !(file.name === "cache" && file.type === "folder"));
        this.drive_period = data.drive_period;
        // 일주일 단위로 파일 삭제
        // if (this.user == "일반사용자") {
        for (let file of this.files) {
            this.nowDate = new Date();
            const updated = file.updated.split(' ')[0];
            const date: Date = new Date(updated);
            this.oneMonthAfter = new Date(date);
            switch (this.drive_period) {
                case '한달':
                    this.oneMonthAfter.setMonth(date.getMonth() + 1);
                    break;
                case '3개월':
                    this.oneMonthAfter.setMonth(date.getMonth() + 3);
                    break;
                case '6개월':
                    this.oneMonthAfter.setMonth(date.getMonth() + 6);
                    break;
                case '1년':
                    this.oneMonthAfter.setFullYear(date.getFullYear() + 1);
                    break;
                case '2년':
                    this.oneMonthAfter.setFullYear(date.getFullYear() + 2);
                    break;
                default:
                    console.log('Invalid drive period');
                    break;
            }
            if (this.nowDate > this.oneMonthAfter) {
                let { code, data } = await wiz.call("remove", { base: this.base, file: JSON.stringify(file) });
                if (code !== 200) {
                    alert("remove 과정에서 오류가 발생했습니다.");
                    return;
                }
            }
            // }
        }


        this.dirName = "";
        this.path = this.base.replace(this.root, '');
        this.mkdir = false;
        this.selectAll = false;

        await this.service.render();
    }

    private async list(node: FileNode) {
        let { code, data } = await wiz.call("list", { path: this.base });

        data = data.map(item => new FileNode(item.name, item.path, item.type, node, node.level + 1));

        return data;
    }

    private async move(node: FileNode) {
        if (node === undefined) {
            await this.init();
            return;
        }

        if (node.type === "folder") {
            this.base = node.path;
        }

        for (let i = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].active = false;
        }

        if (node.extended == true) {
            node.parent.active = true;
            await this.dataSource.toggle(node, false);
            this.base = this.base.substring(0, this.base.lastIndexOf('/'));
            await this.load();
            return;
        }

        node.active = true;

        await this.dataSource.toggle(node, true);
        await this.load();
    }

    private async remove() {

        let check = false;

        for (let file of this.files) {
            if (file.checked) {
                check = true;
            }
        }

        if (check) {

            let alert = await this.service.alert.show({
                title: "",
                message: "정말 이 파일을 삭제하겠습니까?",
                cancel: "No",
            });

            if (!alert) {
                return;
            }

            for (let file of this.files) {
                if (file.checked) {
                    let { code, data } = await wiz.call("remove", { base: this.base, file: JSON.stringify(file) });
                    if (code !== 200) {
                        alert("remove 과정에서 오류가 발생했습니다.");
                        return;
                    }
                }
            }
            let res = this.dataSource.data.find(item => item.path === this.base);
            await this.refresh(res);
        }

        else {
            let alert = await this.service.alert.show({
                title: "",
                message: "선택된 파일이 없습니다.",
                cancel: "",
                action: "OK",
            });
            return;
        }

        await this.load();
    }

    private async create() {
        if (!this.mkdir) {
            this.mkdir = true;
            setTimeout(async () => {
                const inputEl = document.querySelector("#mkdir");
                inputEl.focus();
            }, 300);
        }
        else {
            if (this.dirName.length === 0) {
                return;
            }

            let { code, data } = await wiz.call("create", { base: this.base, dirName: this.dirName });
            if (code !== 200 && code !== 201) {
                this.mkdir = false;
                alert("create 과정에서 오류가 발생했습니다.");
                return;
            }
            else if (code === 201) {
                this.service.toast.error("이미 존재하는 이름입니다.");

                setTimeout(async () => {
                    const inputEl = document.querySelector("#mkdir");
                    inputEl.focus();
                }, 300);

                return;
            }
            this.mkdir = false;

            let res = this.dataSource.data.find(item => item.path === this.base);
            await this.refresh(res);

            await this.load();
        }
        await this.service.render();
    }

    private async upload(data = null) {
        let files = data;
        if (files == null) {
            files = await this.service.file.selectDrive({ accept: '.xls,.xlsx,.csv', multiple: true, folder_size: this.size });
        }

        let fd = new FormData();
        let filepath = [];

        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = this.base;
            else {
                let to = files[i].filepath.substring(0, files[i].filepath.lastIndexOf('/'));
                files[i].filepath = this.base + "/" + to;
            }
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }

        fd.append("filepath", JSON.stringify(filepath));
        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);
        let res = this.dataSource.data.find(item => item.path === this.base);
        await this.refresh(res);

        await this.load();
    }

    private async drop($event) {
        $event.preventDefault();
        let files = await this.service.file.drop($event);

        await this.upload(files);
    }

    private async checkAll() {
        for (const file of this.files) {
            file.checked = this.selectAll;
            if (file.checked) {
                this.show = true;
            }
            else {
                this.show = false;
            }
        }
    }

    private async checked(file) {
        file.checked = !file.checked;
        this.selectAll = this.files.every(file => file.checked);
        let sum = 0;
        for (let file of this.files) {
            if (file.checked) {
                sum += 1;
            }
        }
        if (sum >= 2) {
            this.show = true;
        }
        else {
            this.show = false;
        }


        // macro
        this.macroTF = false;
        if (file.checked == true) {
            this.fileSelect.push(file);
        } else {
            let copy = this.fileSelect;
            this.fileSelect = copy.filter((element) => element !== file);
        }
        if (this.fileSelect.length == 1 && this.fileSelect[0].type == "file") {
            let { code, data } = await wiz.call("macro", { "filepath": file.path });
            if (code != 200) return;
            this.macro = data;
            this.macroTF = true;
        }
        await this.service.render();
    }

    public async download(file) {

        const fileName = btoa(encodeURIComponent(file.name));
        let download = wiz.url("download?path=" + this.base + "&name=" + fileName)
        window.open(download, '_blank');

    }


    public async checkdownload() {

        // 폴더 및 파일 체크 확인
        let check = false;
        for (let file of this.files) {
            if (file.checked) {
                check = true;
            }
        }


        if (check) {
            // 체크된 폴더 및 파일 경로, 이름 배열에 저장
            let checkedFiles = [];

            for (let file of this.files) {
                if (file.checked) {
                    checkedFiles.push({ path: file.path, name: file.name })
                }
            }
            // api로 보내줄 정보를 객체로 저장
            let obj = { base: this.base, file: JSON.stringify(checkedFiles) };
            const params = new URLSearchParams(obj).toString(); //객체값을 쿼리값으로 변환
            //checkdownload라는 api로 전달하여 필요한 정보 가져오기 
            let download = wiz.url("checkdownload?" + params)
            window.open(download, '_blank');
            //key값으로 download 로직 수행
        }

    }


    public close() {
        this.macroTF = false;
        this.selected = "none";
        this.fileSelect[0].checked = false;
        this.fileSelect = [];
        this.service.render();
        this.load();
    }

    public async macroSelect(selected) {
        this.macroFilepath = this.fileSelect[0].path;
        this.selected = selected;
        await this.service.render();
    }

    private async goBack() {
        this.fileSelect = [];
        this.macroTF = false;
        let oldPath = this.base;
        this.base = this.base.substring(0, this.base.lastIndexOf('/'));

        let res = this.dataSource.data.find(item => item.path === oldPath);
        await this.move(res);
    }

    private async open(file) {
        this.fileSelect = [];
        this.macroTF = false;
        let filePath = file.path.split(this.root);
        file.path = this.root + filePath.slice(1).join(this.root);

        let res = this.dataSource.data.find(item => item.name === file.name && item.path === file.path);
        if (res) {
            await this.move(res);
        }
    }

    private async rename(file, i) {
        if (!this.isRenaming[i]) {
            this.isRenaming[i] = true;
            setTimeout(async () => {
                const inputEl = document.querySelector("#rename");
                inputEl.focus();
            }, 300);
        }
        else {
            if (file.name.length === 0) {
                this.service.toast.error("이름은 비워둘 수 없습니다");
                return;
            }

            let filter = this.files.filter(item => item.name === file.name);

            let { code } = await wiz.call("rename", { base: this.base, name: file.name, path: file.path });
            if (code === 200) {
                this.isRenaming[i] = false;

                let filePath = file.path.split(this.root);
                file.path = this.root + filePath.slice(1).join(this.root);

                let res = this.dataSource.data.find(item => item.path === this.base);
                await this.refresh(res);

                await this.load();
            }
            else if (code === 201) {
                this.isRenaming[i] = false;
                this.service.toast.error("존재하지 않는 파일입니다.");
                return;
            }
            else if (code === 202) {
                this.service.toast.error("이미 존재하는 이름입니다.");

                setTimeout(async () => {
                    const inputEl = document.querySelector("#rename");
                    inputEl.focus();
                }, 300);

                return;
            }
            else {
                this.isRenaming[i] = false;
                alert("rename 과정에서 오류가 발생했습니다.");
                return;
            }
        }
        await this.service.render();
    }


    public async refresh(node: FileNode | null = null) {
        if (node && node.parent) {
            await this.dataSource.toggle(node, false);
            await this.dataSource.toggle(node, true);
        } else {
            let data = await this.list(this.rootNode);
            this.dataSource.data = data;
        }
    }

    public filesize(value) {
        if (!value) return "--";
        let kb = value / 1024;
        if (kb < 1) return value + "B";
        let mb = kb / 1024;
        if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
        let gb = mb / 1024;
        if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
        return Math.round(gb * 100) / 100 + "GB";
    }
}

export default PortalShareDriveComponent;