import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explanation.explanation');
import { OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/ckeditor/ckeditor";
import { ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wiz-portal-detail-explanation-explanation',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/portal.detail.explanation.explanation/view.scss */
.data-content {
  width: 1208px;
  background: none;
  padding: 30px;
}

.data-result {
  width: 572px;
  position: relative;
}
.data-result .card {
  width: 483px;
  margin: 30px;
  background-color: #F7F7FA;
  border-radius: 10px;
  border: none;
}
.data-result .card .card-body {
  margin: 10px;
}
.data-result .table {
  width: 423px;
  height: 180px;
  font-size: 20px;
  font-family: "MAIN-M";
}
.data-result .table th {
  background-color: #F0F1F9;
  font-size: 14px;
  vertical-align: middle;
  color: #777986;
  border-top: 2px solid #D2D4DA;
}
.data-result .table tbody {
  background: white;
  vertical-align: middle;
  border-bottom: 2px solid #D2D4DA;
}
.data-result .table th:first-child,
.data-result .table td:first-child {
  border-right: 1px solid #D2D4DA;
}
.data-result .explain {
  margin-bottom: 20px;
  color: #777986;
  font-size: 14px;
}
.data-result .btn-chd {
  width: 423px;
  height: 72px;
  background: #E5E2F5;
  color: #4F40F1;
  text-align: center;
  font-family: "MAIN-B";
  font-size: 30px;
  border-color: #4F40F1;
  border-left: none;
  border-right: none;
  pointer-events: none;
}
.data-result .button_control {
  position: relative;
  right: 106%;
  top: -30px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.data-result .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.data-result .button_control:hover .tooltip {
  opacity: 1;
}
.data-result .button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.data-result .button_control .tooltip {
  position: absolute;
  top: -45px;
  left: -230px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.btn-data {
  border-radius: 10px;
  background: #4F40F1;
  font-family: "MAIN-M";
  color: #FFFFFF;
  width: 423px;
  height: 45px;
  border: 0px;
  font-size: 20px;
  font-weight: bold;
}

.btn-sop {
  width: 423px;
  margin-bottom: 10px;
  color: #4F40F1;
  background-color: #E5E2F5;
  border: none;
  font-size: 16px;
  font-weight: normal;
}

.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 1000;
  pointer-events: none;
}`],
})
export class PortalDetailExplanationExplanationComponent implements OnInit, OnChanges {
    @Input() list: any = {};
    @Input() version: any;
    @Output() offcanvas = new EventEmitter<boolean>();

    @ViewChild('editor')
    public editorElement: ElementRef;

    public editor: any;

    public category_ls = {
        "기기": "device",
        "혈자리": "blood",
        "한약재": "herbal",
        "의료기관": "medical",
        "탕약": "decoction"
    };

    public all: any;
    public fileList = [{ name: '', path: '', row: '' },];
    public fileListLoad: boolean = false;
    public fileInfoAll = [];

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loading(true);
        await this.load();
        await this.loading(false);
        await this.buildEditor();
        await this.service.render();
    }

    public ngOnChanges({ version }) {
        if (!version) return;
        const { currentValue } = version;
        if (['all', 'raw', 'cleansing', 'synthetic'].includes(currentValue)) {
            this.version = currentValue;
            if (this.results == undefined) {
                this.num_info();
                return
            }
            this.load();
        }
    }

    public results: any;
    public result: any;

    public async num_info() {
        const { code, data } = await wiz.call("numInfo", { "datacode": this.list.datacode });
        this.results = data;
    }

    public async load() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("load", { "id": this.dataset_id, "version": this.version, datacode: this.list.datacode });
        this.info = data.dataInfo;

        if (code != 201) {
            this.fileListLoad = true
            this.fileList = data.fileList;
        }
        this.result = this.results['all']

        if (this.version == 'all') {
            this.fileInfo = this.result.filter(item => item.year !== 'ALL');
            this.fileInfoAll = this.result.filter(item => item.year === 'ALL')[0]['row'].toLocaleString();
        } else if (this.version == 'cleansing') {
            this.fileInfo = this.result.filter(item => item.year !== '재현' && item.year !== 'ALL');
            const allR = this.result.find(item => item.year === 'ALL')['row'];
            const synR = this.result.find(item => item.year === '재현')['row'];
            const all = parseInt(allR.replace(/,/g, ''), 10);
            const syn = parseInt(synR.replace(/,/g, ''), 10);
            
            const res = all - syn;
            this.fileInfoAll = res.toLocaleString();
        } else {
            this.fileInfo = this.result.filter(item => item.year == '재현');
            this.fileInfoAll = this.fileInfo[0]['row'].toLocaleString();
        }

        this.result.forEach(item => {
            if (item.year === 'syn') item.year = '재현';
            item.row = item.row.toLocaleString();
        });
        await this.service.render();
    }


    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'fontColor', 'fontBackgroundColor', 'highlight',
                    '|', 'bold', 'italic', 'strikethrough', 'underline', 'code',
                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock'
                ],
                shouldNotGroupWhenFull: true
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://'
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/files/dataset/upload/' + this.category_ls[this.info.category] + "/" + this.info.id
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        this.editor.data.set(this.info.content);
    }

    public async download(file) {
        let download = wiz.url("download?path=" + file.path + "&name=" + file.name)
        window.open(download, '_blank');
        // window.location.href = download; // 새창열기 없이 다운로드
    }

    public async push() {
        this.offcanvas.emit(true);
        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

}

export default PortalDetailExplanationExplanationComponent;