import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.customer');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-page-customer',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig/project/main/build/src/app/page.customer/view.scss */
.container-customer {
  width: 100%;
  height: 70vh;
  justify-items: center;
  align-content: center;
  text-align: center;
}
.container-customer .desc {
  display: block;
  font-size: 20px;
  margin-bottom: 2rem;
}`],
})
export class PageCustomerComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default PageCustomerComponent;